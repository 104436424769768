export var fontSizeDynamic = function (targetPx) {
    var screenWidth = window.innerWidth;
    var baseScreenWidthLarge = 1920;
    var baseScreenWidthSmall = 900;
    var baseFontSizeLarge = 16; // Font size for a 1920px screen width
    var baseFontSizeSmall = 15; // Font size for a 900px screen width
    var minFontSize = 10; // Minimum font size for smaller screens
    var resultFontSize;
    if (screenWidth > baseScreenWidthSmall) {
        // Scale font size based on a larger screen width (e.g., 1920px)
        resultFontSize =
            (screenWidth / baseScreenWidthLarge) * (targetPx / baseFontSizeLarge);
    }
    else {
        // Scale font size based on a smaller screen width (e.g., 900px)
        resultFontSize =
            (screenWidth / baseScreenWidthSmall) * (targetPx / baseFontSizeSmall);
        resultFontSize = Math.max(resultFontSize, minFontSize / baseFontSizeSmall); // Cap to min font size
    }
    return "".concat(resultFontSize, "rem");
};
