var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery, } from '@mui/material';
import './CandidateUpdate.scss';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPCard from '../../../../tandym-web-common/src/shared/components/card/TPCard';
import { useNavigate } from 'react-router-dom';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { getInitials } from '../../../../tandym-web-common/src/shared/utilities/Candidate';
import locationImg from '../../../../tandym-web-common/src/images/location.png';
var CandidateUpdate = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var profileData = props.profileData, entitlementsData = props.entitlementsData;
    var navigate = useNavigate();
    var isScreenSmall = window.innerWidth < 600;
    var isScreenSmall900 = useMediaQuery('(max-width:900px)');
    var navigationHandler = function (path, actionTitle, externalUrl) {
        if (externalUrl && externalUrl !== 'none') {
            window.open(externalUrl, '_blank', 'width=1000,height=700,top=20,left=20');
        }
        else {
            var url = new URL(path);
            var updatedPath = url.pathname;
            ReactGA.event({
                category: 'Candidate',
                action: 'Click',
                label: "".concat(actionTitle, " action button"),
            });
            navigate(updatedPath);
        }
    };
    var getFontSize = function () { return (window.innerWidth > 1536 ? 24 : 19.2); };
    var fullName = profileData
        ? "".concat((_b = (_a = profileData.generalDetails) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '', " ").concat((_d = (_c = profileData.generalDetails) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '')
        : '';
    var location = profileData
        ? [
            (_e = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _e === void 0 ? void 0 : _e.city,
            (_f = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _f === void 0 ? void 0 : _f.state,
            (_g = profileData === null || profileData === void 0 ? void 0 : profileData.contactDetails) === null || _g === void 0 ? void 0 : _g.country,
        ]
            .filter(Boolean)
            .join(', ')
        : '';
    var widthAndHeightForImages = window.innerWidth / 60;
    return (_jsxs(Box, __assign({ id: 'candidate-update', "data-testid": 'candidate-update' }, { children: [isScreenSmall && (_jsxs(Box, __assign({ className: 'candidate-info-container', sx: { mb: '12px' } }, { children: [_jsx(Box, __assign({ id: 'section-1', sx: { background: '#000', height: '101px', position: 'relative' } }, { children: _jsx(IconButton, __assign({ className: 'candidate-edit-icon', onClick: function () { return navigate('/profile/personal-details'); }, sx: { position: 'absolute' } }, { children: _jsx(TPSvgIcon, { resourceName: 'edit-icon', className: 'candidate-edit-icon-svg' }) })) })), _jsxs(Box, __assign({ id: 'section-2' }, { children: [_jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'center' } }, { children: _jsx(TPSvgIcon, { id: 'avatar-icon', "data-testid": 'avatar-icon', resourceName: 'avatar-icon', "font-size": getFontSize(), className: 'candidate-avatar-icon', text: getInitials(fullName) }) })), _jsxs(Box, __assign({ sx: { textAlign: 'center' } }, { children: [_jsxs(Typography, __assign({ className: 'candidate-name', id: 'candidate-update-name', "data-testid": 'candidate-update-name', fontSize: '0.875rem' }, { children: [(_h = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _h === void 0 ? void 0 : _h.firstName, ' ', (_j = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _j === void 0 ? void 0 : _j.lastName] })), _jsx(Typography, __assign({ className: 'candidate-course', id: 'candidate-info-course', "data-testid": 'candidate-info-course', fontSize: fontSizeDynamic(14) }, { children: (_k = profileData === null || profileData === void 0 ? void 0 : profileData.education[0]) === null || _k === void 0 ? void 0 : _k.course })), _jsxs(Box, __assign({ sx: {
                                            display: 'flex',
                                            gap: '0.313rem',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mt: '0.563rem',
                                        } }, { children: [_jsx("img", { src: locationImg, alt: 'location', width: widthAndHeightForImages, height: widthAndHeightForImages }), _jsx(Typography, __assign({ className: 'candidate-info-location', id: 'candidate-info-location', "data-testid": 'candidate-info-location', fontSize: fontSizeDynamic(14) }, { children: location }))] })), _jsxs(Box, __assign({ sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mt: '17px',
                                        } }, { children: [_jsx(TPSvgIcon, { resourceName: 'down-arrow-icon' }), _jsx(Typography, __assign({ className: 'show-more', fontSize: '0.75rem' }, { children: "Show More" }))] }))] }))] }))] }))), _jsxs(Box, __assign({ className: 'candidate-update-profile' }, { children: [_jsxs(Typography, __assign({ className: 'candidate-update-name', id: 'candidate-update-name', "data-testid": 'candidate-update-name', fontSize: fontSizeDynamic(32) }, { children: ["Welcome ", (_l = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _l === void 0 ? void 0 : _l.firstName, ' ', (_m = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _m === void 0 ? void 0 : _m.lastName, ","] })), _jsx(Typography, __assign({ className: 'candidate-update-info', id: 'candidate-update-info', "data-testid": 'candidate-update-info', fontSize: fontSizeDynamic(16) }, { children: "Stay connected, track progress, and grow with tailored resources just for you." })), _jsx(TPButton, { children: 'Update My Profile', color: 'primary', id: 'update-profile-button', "data-testid": 'update-profile-button', className: 'update-profile-button', sx: { fontSize: isScreenSmall900 ? '0.75rem' : fontSizeDynamic(16) }, onClick: function () { return navigate('/profile'); } })] })), _jsx(Grid, __assign({ container: true, spacing: 2, className: 'candidate-content', sx: { mt: '22px' } }, { children: entitlementsData && entitlementsData.length > 0 ? (entitlementsData.map(function (action, index) {
                    return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(TPCard, __assign({ customStyles: {
                                display: 'flex',
                                flexDirection: 'column',
                            } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsxs(Box, __assign({ className: 'candidate-card-text-header' }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon', className: 'small-vertical-line-icon', width: isScreenSmall ? 16 : 24, height: isScreenSmall ? 20 : 30 }), _jsx(Typography, __assign({ className: 'candidate-update-card-title', id: "candidate-update-card-title-".concat(index), "data-testid": "candidate-update-card-title-".concat(index), fontSize: fontSizeDynamic(20) }, { children: action.title }))] })), _jsx(Typography, __assign({ className: 'candidate-update-info-text', id: "candidate-update-info-text-".concat(index), "data-testid": "candidate-update-info-text-".concat(index), fontSize: fontSizeDynamic(16) }, { children: action.description }))] })), _jsx(Box, __assign({ className: 'candidate-card-button' }, { children: action.actionButtonName ? (_jsx(TPButton, __assign({ color: 'primary', id: "candidate-update-card-btn-".concat(index), "data-testid": "candidate-update-card-btn-".concat(index), sx: {
                                            borderRadius: '0px',
                                            fontSize: isScreenSmall900
                                                ? '0.75rem'
                                                : fontSizeDynamic(16),
                                        }, onClick: function () {
                                            return navigationHandler(action.internalPageId, action.title, action.externalUrl);
                                        } }, { children: action.actionButtonName }))) : null }))] })) }), index));
                })) : (_jsx(Grid, __assign({ container: true }, { children: _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ sx: { textAlign: 'center', fontSize: fontSizeDynamic(16) }, className: 'no-candidate-update-info', id: 'no-candidate-update-info', "data-testid": 'no-candidate-update-info' }, { children: "No Call of Actions available." })) })) }))) }))] })));
};
export default CandidateUpdate;
