var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TPButton from '../../../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPTextInput from '../../../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import TPDropdown from '../../../../../../tandym-web-common/src/shared/components/dropdown/TPDropdown';
import TPProgressModal from '../../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import TPToast from '../../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import { proficiencyOpt, experiencePeriodOpt, } from '../../../../shared/models/SkillsLists';
import { useAPI } from '../../../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../../../shared/Constants';
import './AddSkill.scss';
import { fontSizeDynamic } from '../../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var UpdateSkill = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var onClick = props.onClick, showSuccessMessage = props.successMessage, showErrorMessage = props.errorMessage, skillToEdit = props.skillToEdit, updateSkillsList = props.updateSkillsList, filtered = props.filtered, editSkill = props.editSkill;
    var _k = useState(''), successMessage = _k[0], setSuccessMessage = _k[1];
    var _l = useState(''), errorMessage = _l[0], setErrorMessage = _l[1];
    var _m = useState(false), isLoading = _m[0], setIsLoading = _m[1];
    var _o = useState(''), experiencePeriodValue = _o[0], setExperiencePeriodValue = _o[1];
    var _p = useState(''), proficiencyValue = _p[0], setProficiencyValue = _p[1];
    var _q = useState(''), skillValue = _q[0], setSkillValue = _q[1];
    var _r = useState(''), skillValuedata = _r[0], setSkillValuedata = _r[1];
    var _s = useState(), selectedListedit = _s[0], setSelectedListEdit = _s[1];
    var _t = useState(false), isTPProgressLoading = _t[0], setTPProgressLoading = _t[1];
    var _u = useState({
        skill: (_a = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.skill.name) !== null && _a !== void 0 ? _a : '',
        skillId: (_b = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.skill.id) !== null && _b !== void 0 ? _b : '',
        proficiency: (_c = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.proficiency.name) !== null && _c !== void 0 ? _c : '',
        proficiencyId: (_d = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.proficiency.id) !== null && _d !== void 0 ? _d : '',
        experience: (_e = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.experience) !== null && _e !== void 0 ? _e : '',
        experiencePeriodId: (_f = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.experiencePeriod.id) !== null && _f !== void 0 ? _f : '',
        experiencePeriod: (_g = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.experiencePeriod.name) !== null && _g !== void 0 ? _g : '',
        rowVersion: (_h = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.rowVersion) !== null && _h !== void 0 ? _h : '',
        skillLevelId: (_j = skillToEdit === null || skillToEdit === void 0 ? void 0 : skillToEdit.skillLevelId) !== null && _j !== void 0 ? _j : '',
    }), formValues = _u[0], setFormValues = _u[1];
    var httpPost = useAPI().httpPost;
    var _v = useState(true), btnDisabled = _v[0], setBtnDisabled = _v[1];
    var _w = useState({}), errors = _w[0], setErrors = _w[1];
    var _x = useState([]), filteredSuggest = _x[0], setFilteredSuggestions = _x[1];
    var _y = useState(false), open = _y[0], setOpen = _y[1];
    var _z = useState(''), inputValue = _z[0], setInputValue = _z[1];
    var _0 = useState(''), optionValue = _0[0], setOptionValue = _0[1];
    var _1 = useState(''), errorCode = _1[0], seterrorCode = _1[1];
    useEffect(function () {
        setSkillValuedata(formValues.skill);
        if (editSkill) {
            setSelectedListEdit(editSkill.find(function (option) { return option.label === formValues.skill; }));
        }
        setOptionValue('Loading...');
        var updateFilteredSuggestions = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, response, skillSuggestions, filt, newFilteredSuggestions, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!inputValue) return [3 /*break*/, 6];
                        setOpen(true);
                        setTPProgressLoading(true);
                        data = { QueryString: inputValue };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_TYPE_HEAD_SKILLS_GET, data)];
                    case 2:
                        response = _a.sent();
                        seterrorCode(response.code);
                        if (response.skills) {
                            setOpen(true);
                            skillSuggestions = response.skills;
                            filt = skillSuggestions.map(function (suggestion) { return ({
                                label: suggestion.name,
                                value: suggestion.id,
                            }); });
                            newFilteredSuggestions = filt.filter(function (sugges) {
                                return !filtered.some(function (filteredSuggestion) {
                                    return filteredSuggestion.value === sugges.value;
                                });
                            });
                            if (newFilteredSuggestions.length > 0) {
                                setFilteredSuggestions(newFilteredSuggestions);
                                setOptionValue('Loading...');
                            }
                            else {
                                setOptionValue('No Skill Found');
                            }
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching skill suggestions:', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        setTPProgressLoading(false); // Hide loading modal
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        setFilteredSuggestions([]);
                        setOpen(false);
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); }, 500);
        return function () {
            clearTimeout(updateFilteredSuggestions); // memory cleanup. MUST
        };
    }, [inputValue]);
    useEffect(function () {
        var _a, _b;
        if (formValues.proficiency) {
            var proficiency = (_b = (_a = proficiencyOpt.find(function (item) { return item.label === formValues.proficiency; })) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
            setProficiencyValue(proficiency);
        }
    }, [formValues.proficiency]);
    useEffect(function () {
        var _a, _b;
        if (formValues.experiencePeriod) {
            var experiencePeriod = (_b = (_a = experiencePeriodOpt.find(function (item) { return item.label === formValues.experiencePeriod; })) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
            setExperiencePeriodValue(experiencePeriod);
        }
    }, [formValues.experiencePeriod]);
    var handleFieldChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormValues(function (prevValues) {
            var _a;
            return (__assign(__assign({}, prevValues), (_a = {}, _a[name] = value, _a)));
        });
        setBtnDisabled(false);
    };
    var handleSelectChange = function (e) {
        var value = e.target.value;
        setExperiencePeriodValue(value);
        setBtnDisabled(false);
    };
    var handleProficiencySelectChange = function (e) {
        var value = e.target.value;
        setProficiencyValue(value);
        setBtnDisabled(false);
    };
    var validateFields = function () {
        var newErrors = {};
        if (!skillValuedata) {
            newErrors.skill = 'Please fill out the field "Skill"';
        }
        if (!proficiencyValue) {
            newErrors.proficiency = 'Please select the "Proficiency"';
        }
        if (isNaN(Number(formValues.experience)) ||
            Number(formValues.experience) <= 0) {
            newErrors.experience =
                'Please enter a valid positive number for "Experience"';
        }
        else if (Number(formValues.experience) > 99) {
            newErrors.experience = 'Experience cannot be more than 99';
        }
        if (!experiencePeriodValue) {
            newErrors.experiencePeriod = 'Please select the "Experience Period"';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleSkillChange = function (event, newValue) {
        setOpen(false);
        setBtnDisabled(false);
        setSelectedListEdit(newValue);
        if (newValue) {
            setFormValues(function (prevValues) { return (__assign(__assign({}, prevValues), { skill: newValue.label || '', skillId: newValue.value || '' })); });
            setSkillValuedata(newValue.label || '');
            setSkillValue(newValue.value || '');
        }
        else {
            setFormValues(function (prevValues) { return (__assign(__assign({}, prevValues), { skill: '', skillId: '' })); });
            setSkillValuedata('');
            setSkillValue('');
        }
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!validateFields()) return [3 /*break*/, 5];
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 5]);
                    data = {
                        skillLevelId: formValues.skillLevelId || new Date().toISOString(),
                        skill: {
                            id: skillValue || formValues.skillId,
                            name: skillValuedata || formValues.skill,
                        },
                        proficiency: {
                            id: proficiencyValue,
                            name: (_b = (_a = proficiencyOpt.find(function (item) { return item.value === proficiencyValue; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '',
                        },
                        experiencePeriod: {
                            id: experiencePeriodValue,
                            name: (_d = (_c = experiencePeriodOpt.find(function (item) { return item.value === experiencePeriodValue; })) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : '',
                        },
                        experience: Number(formValues.experience),
                        rowVersion: formValues.rowVersion,
                    };
                    setIsLoading(true);
                    return [4 /*yield*/, updateSkillsList(data)];
                case 2:
                    _e.sent();
                    setSuccessMessage(skillToEdit
                        ? 'Skill updated successfully'
                        : 'Skill added successfully');
                    setBtnDisabled(true);
                    onClick(false);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _e.sent();
                    setErrorMessage('An unexpected error occurred.');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, __assign({ className: 'add-skill', id: 'add-skill', "data-testid": 'min-add-skill' }, { children: [_jsx(TPToast, { message: successMessage, open: !!successMessage, vertical: 'top', horizontal: 'center', type: 'success' }), _jsx(TPToast, { message: errorMessage, open: !!errorMessage, vertical: 'top', horizontal: 'center', type: 'error' }), _jsx(TPProgressModal, { isLoading: isLoading }), _jsxs(Box, __assign({ className: 'top-left-typographies', id: 'top-left-typographies', "data-testid": 'min-top-left-typographies' }, { children: [_jsx(Typography, __assign({ className: 'top-left-text-1', id: 'top-left-text-1', "data-testid": 'min-top-left-text-1', fontSize: fontSizeDynamic(24) }, { children: skillToEdit ? 'Edit Skills' : 'Add Skills' })), _jsx(Typography, __assign({ className: 'descriptionText', id: 'descriptionText', "data-testid": 'min-descriptionText', fontSize: fontSizeDynamic(14) }, { children: skillToEdit
                            ? 'Edit your skills helps your profile align with what exactly recruiters are looking for.'
                            : 'Adding your skills helps your profile align with what exactly recruiters are looking for.' }))] })), _jsxs(Typography, __assign({ className: 'top-left-text-2', id: 'addskill-required-details', fontSize: fontSizeDynamic(14) }, { children: [_jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" })), ' ', _jsx("span", { children: "Mandatory fields." })] })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: { mb: '44px' } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, __assign({ className: 'top-left-text-9', fontSize: fontSizeDynamic(16) }, { children: ["Skill", _jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" }))] })), _jsx(Autocomplete, { id: 'autocomplete-skill', "data-testid": 'min-skill-autocomplete', filterOptions: function (options, _a) {
                                    var inputValue = _a.inputValue;
                                    return options.filter(function (option) {
                                        return option.label &&
                                            option.label.toLowerCase().includes(inputValue.toLowerCase());
                                    });
                                }, open: open, onOpen: function () {
                                    if (inputValue.length > 0) {
                                        setOpen(true);
                                    }
                                }, onClose: function () {
                                    setOpen(false);
                                    setInputValue('');
                                }, sx: { fontSize: fontSizeDynamic(16) }, disableClearable: true, disableCloseOnSelect: true, options: filteredSuggest, value: selectedListedit || null, getOptionLabel: function (option) { return option.label; }, noOptionsText: isTPProgressLoading
                                    ? 'Loading...'
                                    : errorCode === 'TM_SKILLS_005' && inputValue.length > 0
                                        ? optionValue
                                        : optionValue, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: 'Enter Skill', variant: 'standard', onChange: function (event) { return setInputValue(event.target.value); }, sx: { fontSize: fontSizeDynamic(16) }, InputProps: __assign(__assign({}, params.InputProps), { classes: {
                                            input: 'suggester',
                                            root: 'custom-autocomplete-root',
                                        }, endAdornment: (_jsx("div", { className: 'custom-autocomplete-arrow' })), sx: { fontSize: fontSizeDynamic(16) } }) }))); }, onChange: handleSkillChange, freeSolo: false, isOptionEqualToValue: function (option, value) {
                                    return value ? option.value === value.value : false;
                                }, classes: {
                                    option: 'custom-autocomplete-option',
                                } }), errors.skill && (_jsx(Typography, __assign({ className: 'error_message', id: 'skill-error', "data-testid": 'min-skill-error' }, { children: errors.skill })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, __assign({ className: 'top-left-text-9', fontSize: fontSizeDynamic(16) }, { children: ["Proficiency", _jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" }))] })), _jsx(TPDropdown, { name: 'proficiency', options: proficiencyOpt, id: 'proficiency', "data-testid": 'min-proficiency', defaultSelectedOption: proficiencyValue, onChange: handleProficiencySelectChange, placeholder: 'Enter Proficiency Period', placeholderClassName: 'custom-placeholder' }), errors.proficiency && (_jsx(Typography, __assign({ className: 'error_message', id: 'proficiency-error', "data-testid": 'min-proficiency-error' }, { children: errors.proficiency })))] }))] })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: { mb: '44px' } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, __assign({ className: 'top-left-text-9', fontSize: fontSizeDynamic(16) }, { children: ["Experience", _jsx("span", __assign({ style: { fontSize: fontSizeDynamic(16) } }, { children: "*" }))] })), _jsx(TPTextInput, { type: 'number', name: 'experience', id: 'experience', inputProps: {
                                    'data-testid': 'min-experience',
                                    sx: { fontSize: fontSizeDynamic(16) },
                                }, placeholder: 'Enter Value', variant: 'standard', value: formValues.experience, onChange: handleFieldChange, className: 'top-left-text-5' }), errors.experience && (_jsx(Typography, __assign({ className: 'error_message', id: 'experience-error', "data-testid": 'min-experience-error' }, { children: errors.experience })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, __assign({ className: 'top-left-text-9', fontSize: fontSizeDynamic(16) }, { children: ["Experience Period", _jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" }))] })), _jsx(TPDropdown, { name: 'experiencePeriod', options: experiencePeriodOpt, id: 'experiencePeriod', "data-testid": 'min-experiencePeriod', defaultSelectedOption: experiencePeriodValue, onChange: handleSelectChange, placeholder: 'Enter Experience Period', placeholderClassName: 'custom-placeholder' }), errors.experiencePeriod && (_jsx(Typography, __assign({ className: 'error_message', id: 'experiencePeriod-error', "data-testid": 'min-experiencePeriod-error' }, { children: errors.experiencePeriod })))] }))] })), _jsxs(Grid, __assign({ container: true, direction: 'row', justifyContent: 'end', sx: { mt: '10rem' } }, { children: [_jsx(TPButton, __assign({ id: 'discard-button', "data-testid": 'discard-button', color: 'secondary', onClick: function () { return onClick(false); }, sx: { mr: '1.88rem', fontSize: fontSizeDynamic(14) } }, { children: "Cancel" })), _jsx(TPButton, __assign({ id: 'save-button', "data-testid": 'min-save-button', color: 'primary', onClick: handleSubmit, disabled: btnDisabled, sx: { fontSize: fontSizeDynamic(14) } }, { children: skillToEdit ? 'Save Skills' : 'Save Skills' }))] }))] })));
};
export default UpdateSkill;
