var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, FormLabel, FormControlLabel, Checkbox, } from '@mui/material';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPTextInput from '../../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import './Preference.scss';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import RoleSoughtSelect from './roleSoughtSelect/RoleSoughtSelect';
import { useCandidateApiContext } from '../Profile';
import { useAPI } from '../../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../../shared/Constants';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { NoticePeriodType } from '../../../shared/models/Home';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import ContactPreference from './contactPreference/ContactPreference';
import TPCustomModal from '../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPToast from '../../../../../tandym-web-common/src/shared/components/toast/TPToast';
import TPDropdown from '../../../../../tandym-web-common/src/shared/components/dropdown/TPDropdown';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import NavigationGuard from '../navigationGuard/navigationGuard';
import NoPageFound from '../../../../../tandym-web-common/src/components/NoPageFound';
import { setTabNavigationGuard, setNavigationGuardModalPopup, setNavigationHistory, } from '../../../store/tabNavigationGuard';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import handleSmallMediumLargeSize from '../../../../../tandym-web-common/src/shared/utilities/size';
var Preference = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var dispatch = useAppDispatch();
    var _t = useCandidateApiContext(), profileData = _t.profileData, refreshProfile = _t.refreshProfile;
    var _u = useAppSelector(function (state) { return state.tabNavigationGuard; }), navigationGuardModalPopup = _u.navigationGuardModalPopup, navigationHistory = _u.navigationHistory;
    var _v = useState(false), isLoading = _v[0], setIsLoading = _v[1];
    var _w = useState(''), successMessage = _w[0], setSuccessMessage = _w[1];
    var _x = useState(''), errorMessage = _x[0], setErrorMessage = _x[1];
    var _y = useState(''), cpSuccess = _y[0], setCPSuccess = _y[1];
    var _z = useState(''), cpError = _z[0], setCPError = _z[1];
    var httpPost = useAPI().httpPost;
    var _0 = useState(false), isSelectContact = _0[0], setIsSelectContact = _0[1];
    var _1 = useState(true), btnDisabled = _1[0], setBtnDisabled = _1[1];
    var _2 = useState(false), showToast = _2[0], setShowToast = _2[1];
    var candidateId = useAppSelector(function (state) { return state.userAuth; }).candidateId;
    var navigate = useNavigate();
    var preferences = profileData === null || profileData === void 0 ? void 0 : profileData.preferences;
    var initialPreferences = {
        employmentType: {
            contract: (_a = preferences === null || preferences === void 0 ? void 0 : preferences.employment.employmentType.isContractor) !== null && _a !== void 0 ? _a : false,
            temporary: (_b = preferences === null || preferences === void 0 ? void 0 : preferences.employment.employmentType.isTemporary) !== null && _b !== void 0 ? _b : false,
            permanent: (_c = preferences === null || preferences === void 0 ? void 0 : preferences.employment.employmentType.isPermanent) !== null && _c !== void 0 ? _c : false,
        },
        noticePeriod: (_d = preferences === null || preferences === void 0 ? void 0 : preferences.noticePeriod) !== null && _d !== void 0 ? _d : '',
        days: (_e = preferences === null || preferences === void 0 ? void 0 : preferences.noticePeriodType) !== null && _e !== void 0 ? _e : '',
        // rolesSought:
        //   profileData?.rolesSought?.split(',').map((role: any) => role.trim()) ??
        //   ([] as string[] | any),
        currency: (_f = preferences === null || preferences === void 0 ? void 0 : preferences.currency) !== null && _f !== void 0 ? _f : '',
        payFrequency: (_g = preferences === null || preferences === void 0 ? void 0 : preferences.payFrequency) !== null && _g !== void 0 ? _g : '',
        minSalary: (_j = (_h = preferences === null || preferences === void 0 ? void 0 : preferences.expectedSalaryLow) === null || _h === void 0 ? void 0 : _h.replace('$', '').replace(/,/g, '').split('.')[0]) !== null && _j !== void 0 ? _j : '',
        maxSalary: (_l = (_k = preferences === null || preferences === void 0 ? void 0 : preferences.expectedSalaryHigh) === null || _k === void 0 ? void 0 : _k.replace('$', '').replace(/,/g, '').split('.')[0]) !== null && _l !== void 0 ? _l : '',
        minRate: (_o = (_m = preferences === null || preferences === void 0 ? void 0 : preferences.expectedRateLow) === null || _m === void 0 ? void 0 : _m.replace('$', '').replace(/,/g, '')) !== null && _o !== void 0 ? _o : '',
        maxRate: (_q = (_p = preferences === null || preferences === void 0 ? void 0 : preferences.expectedRateHigh) === null || _p === void 0 ? void 0 : _p.replace('$', '').replace(/,/g, '')) !== null && _q !== void 0 ? _q : '',
        salaryPeriod: (_r = preferences === null || preferences === void 0 ? void 0 : preferences.payFrequency) !== null && _r !== void 0 ? _r : '',
        noticePeriodType: (_s = preferences === null || preferences === void 0 ? void 0 : preferences.noticePeriodType) !== null && _s !== void 0 ? _s : '',
        ratePeriod: '',
    };
    var _3 = useState(initialPreferences), formData = _3[0], setFormData = _3[1];
    var _4 = useState(dayjs(preferences === null || preferences === void 0 ? void 0 : preferences.availability)), dateValue = _4[0], setDateValue = _4[1];
    var _5 = useState(false), saveBtnClicked = _5[0], setSaveBtnClicked = _5[1];
    var _6 = useState({}), errors = _6[0], setErrors = _6[1];
    var _7 = useState(true), renderComponent = _7[0], setRenderComponent = _7[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        setRenderComponent(entitlementsData.entitlementDto.profileJson.preference);
    }, [entitlementsData]);
    // To prevent the user from navigating away from the page without saving the changes
    var isPreferenceFormModified = JSON.stringify(formData) !== JSON.stringify(initialPreferences);
    useEffect(function () {
        if (isPreferenceFormModified) {
            dispatch(setTabNavigationGuard({
                mainTabNavigationGuard: true,
            }));
        }
        else {
            dispatch(setTabNavigationGuard({
                mainTabNavigationGuard: false,
            }));
        }
    }, [formData, profileData === null || profileData === void 0 ? void 0 : profileData.preferences]);
    // Update the state when the profileData changes
    useEffect(function () {
        setFormData(initialPreferences);
        setDateValue(dayjs(preferences === null || preferences === void 0 ? void 0 : preferences.availability));
    }, [profileData]);
    var handleInputChange = function (field) {
        return function (event) {
            var value = event.target.value;
            setBtnDisabled(false);
            if (field === 'noticePeriod') {
                if (value === '') {
                    setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { noticePeriod: 'noticePeriod' })); });
                }
                else {
                    setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { noticePeriod: '' })); });
                }
            }
            setFormData(function (prev) {
                var _a;
                if ([
                    'minRate',
                    'maxRate',
                    'ratePeriod',
                    'minSalary',
                    'maxSalary',
                    'salaryPeriod',
                ].includes(field) &&
                    value === '') {
                    setErrors(function (prevErrors) {
                        var _a;
                        return (__assign(__assign(__assign(__assign({}, prevErrors), (_a = {}, _a[field] = field, _a)), (field === 'minRate' || field === 'maxRate'
                            ? { rateValidation: '' }
                            : {})), (field === 'minSalary' || field === 'maxSalary'
                            ? { salaryValidation: '' }
                            : {})));
                    });
                }
                else {
                    setErrors(function (prevErrors) {
                        var _a;
                        return (__assign(__assign({}, prevErrors), (_a = {}, _a[field] = '', _a)));
                    });
                }
                return __assign(__assign({}, prev), (_a = {}, _a[field] = value, _a));
            });
        };
    };
    var handleCheckboxChange = function (field) { return function (event) {
        setBtnDisabled(false);
        var isChecked = event.target.checked;
        if (field === 'permanent') {
            setFormData(function (prev) {
                var _a;
                return __assign(__assign({}, prev), { employmentType: __assign(__assign({}, prev.employmentType), (_a = {}, _a[field] = isChecked, _a)), minSalary: isChecked ? prev.minSalary : '', maxSalary: isChecked ? prev.maxSalary : '' });
            });
            setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { permanentError: '', minSalary: isChecked ? prevErrors.filed : '', maxSalary: isChecked ? prevErrors.filed : '' })); });
            return;
        }
        if (field === 'contract' || field === 'temporary') {
            setFormData(function (prev) {
                var _a;
                return __assign(__assign({}, prev), { employmentType: __assign(__assign({}, prev.employmentType), (_a = {}, _a[field] = isChecked, _a)), minRate: isChecked ? prev.minRate : '', maxRate: isChecked ? prev.maxRate : '' });
            });
            setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { contractError: '', minRate: isChecked ? prevErrors.filed : '', maxRate: isChecked ? prevErrors.filed : '' })); });
            return;
        }
    }; };
    var handleDiscardChanges = function () {
        setFormData(initialPreferences);
        setDateValue(dayjs(preferences === null || preferences === void 0 ? void 0 : preferences.availability));
        setErrors({});
        dispatch(setNavigationGuardModalPopup({
            navigationGuardModalPopup: false,
        }));
        dispatch(setTabNavigationGuard({
            mainTabNavigationGuard: false,
        }));
        dispatch(setNavigationHistory({
            navigationHistory: '',
        }));
        setSaveBtnClicked(false);
        setBtnDisabled(true);
        setShowToast(true);
        var toastTimeout = setTimeout(function () {
            setShowToast(false);
        }, 3000);
        return function () { return clearTimeout(toastTimeout); };
    };
    var handleClick = function (field) { return function () {
        if (field === 'minRate' || field === 'maxRate' || field === 'ratePeriod') {
            if (!formData.employmentType.contract) {
                setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { contractError: 'Please check the Contract employment type.' })); });
            }
            else {
                setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { contractError: '' })); });
            }
        }
        else {
            if (!formData.employmentType.permanent) {
                setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { permanentError: 'Please check the Permanent employment type.' })); });
            }
            else {
                setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { permanentError: '' })); });
            }
        }
    }; };
    useEffect(function () {
        if (!isLoading && successMessage) {
            var timeout_1 = setTimeout(function () {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 1000);
            return function () { return clearTimeout(timeout_1); };
        }
    }, [isLoading, successMessage]);
    var checkValidations = function () {
        var newErrors = {};
        if (!dateValue || !dateValue.isValid()) {
            newErrors.availability = 'availability';
        }
        if (!formData.noticePeriod) {
            newErrors.noticePeriod = 'noticePeriod';
        }
        if (![0, 1, 2].includes(+formData.noticePeriodType) ||
            formData.noticePeriodType === '') {
            newErrors.noticePeriodType = 'noticePeriodType';
        }
        if (formData.employmentType.contract) {
            if (!formData.minRate) {
                newErrors.minRate = 'minRate';
            }
            if (!formData.maxRate) {
                newErrors.maxRate = 'maxRate';
            }
            if (formData.minRate &&
                formData.maxRate &&
                +formData.minRate.replace('$', '') >= +formData.maxRate.replace('$', '')) {
                newErrors.rateValidation = 'rateValidation';
            }
        }
        if (formData.employmentType.permanent) {
            if (!formData.minSalary) {
                newErrors.minSalary = 'minSalary';
            }
            if (!formData.maxSalary) {
                newErrors.maxSalary = 'maxSalary';
            }
            if (formData.minSalary &&
                formData.maxSalary &&
                +formData.minSalary.replace('$', '') >=
                    +formData.maxSalary.replace('$', '')) {
                newErrors.salaryValidation = 'salaryValidation';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleSavePreferences = function (isFrom) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedFormData, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isFrom === 'save' &&
                        dispatch(setNavigationHistory({
                            navigationHistory: '',
                        }));
                    setSaveBtnClicked(true);
                    ReactGA.event({
                        category: 'Candidate',
                        action: 'Click',
                        label: 'Save Prefrence Button',
                    });
                    if (!checkValidations()) {
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    updatedFormData = {
                        preferences: {
                            availability: (dateValue === null || dateValue === void 0 ? void 0 : dateValue.format('YYYY-MM-DD')) || '',
                            noticePeriod: formData.noticePeriod,
                            noticePeriodType: formData.noticePeriodType,
                            isContractor: formData.employmentType.contract,
                            isTemporary: formData.employmentType.contract,
                            isPermanent: formData.employmentType.permanent,
                            expectedSalaryLow: formData.minSalary === '' ? null : formData.minSalary,
                            expectedSalaryHigh: formData.maxSalary === '' ? null : formData.maxSalary,
                            expectedRateLow: formData.minRate === '' ? null : formData.minRate,
                            expectedRateHigh: formData.maxRate === '' ? null : formData.maxRate,
                        },
                        // rolesSought: formData.rolesSought.join(','),
                        isCandidateContact: true,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    if (!candidateId) {
                        setIsLoading(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_PROFILE_UPDATE, updatedFormData, { candidateId: candidateId })];
                case 2:
                    response = _a.sent();
                    if (response.code === 'TM_CANDIDATE_004' && response.error === false) {
                        refreshProfile();
                        setSuccessMessage(response.message);
                        setBtnDisabled(true);
                        dispatch(setNavigationGuardModalPopup({
                            navigationGuardModalPopup: false,
                        }));
                        dispatch(setTabNavigationGuard({
                            mainTabNavigationGuard: false,
                        }));
                        if (navigationHistory && navigationGuardModalPopup) {
                            navigate(isFrom === 'save' ? '' : navigationHistory);
                        }
                    }
                    if (response.code === 'TM_CANDIDATE_002' && response.error === true) {
                        setErrorMessage(response.message);
                    }
                    if (response.status === 400) {
                        setErrorMessage(response.title);
                    }
                    if (response.status === 401) {
                        setErrorMessage(response.title);
                        navigate('/login');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error saving preferences:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSetDurationChange = function (e) {
        setBtnDisabled(false);
        setFormData(function (prev) { return (__assign(__assign({}, prev), { noticePeriodType: e.target.value })); });
        setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { noticePeriodType: '' })); });
    };
    useEffect(function () {
        var timeOut = setTimeout(function () {
            setSuccessMessage('');
            setErrorMessage('');
            setCPSuccess('');
            setCPError('');
        }, 3000);
        return function () { return clearTimeout(timeOut); };
    }, [successMessage, cpSuccess]);
    var noticePeriodOptions = Object.keys(NoticePeriodType)
        .filter(function (key) { return isNaN(Number(key)); }) // Filter out numeric keys
        .map(function (key) { return ({
        label: key,
        value: NoticePeriodType[key],
    }); });
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsxs(Box, __assign({ className: 'preference', id: 'preference-tab', "data-testid": 'preference-tab' }, { children: [_jsx(TPToast, { message: successMessage || cpSuccess, open: successMessage || cpSuccess !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'success' }), _jsx(TPToast, { message: errorMessage || cpError, open: errorMessage || cpError !== '' ? true : false, vertical: 'top', horizontal: 'center', type: 'error' }), showToast && (_jsx(TPToast, { message: 'Your changes has been discarded', open: showToast, vertical: 'top', horizontal: 'center', type: 'info' })), _jsx(Box, __assign({ sx: {
                    display: 'flex',
                    justifyContent: 'end',
                    mb: '15px',
                } }, { children: _jsxs(Typography, __assign({ className: 'preference-required-details', id: 'preference-required-details' }, { children: [_jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" })), ' ', _jsx("span", __assign({ style: {
                                fontSize: fontSizeDynamic(16),
                            } }, { children: "Mandatory fields." }))] })) })), _jsxs(Box, __assign({ sx: {
                    display: { xs: 'block', sm: 'flex' },
                    justifyContent: { sm: 'space-between' },
                    mb: '48px',
                } }, { children: [_jsx(Typography, __assign({ className: 'your-preference', fontSize: fontSizeDynamic(24) }, { children: "Set Your Preferences" })), _jsx(TPButton, __assign({ variant: 'outlined', className: 'contact-preference-btn', id: 'contact-preference-btn', "data-testid": 'contact-preference-btn', sx: { mt: { xs: '20px', sm: '0' }, fontSize: fontSizeDynamic(14) }, onClick: function () { return setIsSelectContact(true); } }, { children: "Contact Preferences" }))] })), _jsxs(Grid, __assign({ container: true, spacing: 6, sx: { mb: '44px', alignItems: 'center' } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4, sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs("div", { children: [' ', _jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Availability" })), _jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" }))] }), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { className: 'date-picker', sx: { fontSize: fontSizeDynamic(16) }, value: dateValue, onChange: function (newValue) {
                                        setDateValue(newValue);
                                        setBtnDisabled(false);
                                        setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { availability: '' })); });
                                    }, slotProps: {
                                        textField: {
                                            variant: 'standard',
                                            inputProps: {
                                                className: 'date-picker',
                                                id: 'date-picker',
                                                'data-testid': 'date-picker',
                                                style: { fontSize: fontSizeDynamic(16) },
                                            },
                                        },
                                    }, slots: {
                                        openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: 'date-picker-icon', id: 'date-picker-icon', "data-testid": 'date-picker-icon' })); },
                                    } }) })), errors.availability && (_jsx(Typography, __assign({ id: 'availability-error', "data-testid": 'availability-error', className: 'error_message', fontSize: fontSizeDynamic(16) }, { children: "Please fill out the field \"".concat(errors.availability, "\"") })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Employment Type" })), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', mt: '20px' } }, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: formData.employmentType.contract, size: handleSmallMediumLargeSize(window.innerWidth), onChange: handleCheckboxChange('contract'), id: 'contract', "data-testid": 'contract' }), label: 'Contract', classes: { label: 'formLabel' }, componentsProps: {
                                            typography: {
                                                sx: { fontSize: fontSizeDynamic(16) },
                                            },
                                        } }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: formData.employmentType.permanent, size: handleSmallMediumLargeSize(window.innerWidth), onChange: handleCheckboxChange('permanent'), id: 'permanent', "data-testid": 'permanent' }), label: 'Permanent', classes: { label: 'formLabel' }, componentsProps: {
                                            typography: {
                                                sx: { fontSize: fontSizeDynamic(16) },
                                            },
                                        } })] }))] }))] })), _jsxs(Grid, __assign({ container: true, spacing: 6 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Notice Period" })), _jsx("span", __assign({ className: 'required', style: { fontSize: fontSizeDynamic(16) } }, { children: "*" })), _jsxs(Grid, __assign({ container: true, spacing: 2, alignItems: 'center', mt: 1 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 6 }, { children: [_jsx(TPTextInput, { placeholder: 'Enter Values', value: formData.noticePeriod, type: 'number', onChange: handleInputChange('noticePeriod'), variant: 'standard', inputProps: {
                                                    className: 'notice-period',
                                                    'data-testid': 'notice-period',
                                                    min: 0,
                                                    sx: { fontSize: fontSizeDynamic(16) },
                                                }, id: 'notice-period' }), errors.noticePeriod && (_jsx(Typography, __assign({ color: 'error', id: 'notice-period-error', "data-testid": 'notice-period-error', className: 'error_message', fontSize: fontSizeDynamic(16) }, { children: "Please fill out the field \"".concat(errors.noticePeriod, "\"") })))] })), _jsxs(Grid, __assign({ item: true, xs: 6 }, { children: [_jsx(TPDropdown, { sx: {
                                                    '&.tp-dropdown::before': {
                                                        content: '""',
                                                        borderBottom: '1px solid #d9d9d9',
                                                    },
                                                }, id: 'set-duration', placeholder: 'Set Duration', "data-testid": 'set-duration-select', defaultSelectedOption: formData.noticePeriodType, options: noticePeriodOptions, name: 'setDuration', placeholderClassName: 'preference-set-duration', onChange: handleSetDurationChange }), errors.noticePeriodType && (_jsx(Typography, __assign({ color: 'error', className: 'error_message', id: 'notice-period-type-error', "data-testid": 'notice-period-type-error' }, { children: "Please fill out the field \"Set Duration\"" })))] }))] }))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Currency" })), _jsx(TPTextInput, { value: formData.currency, variant: 'standard', placeholder: 'US Dollar', inputProps: {
                                    className: 'currency',
                                    sx: { fontSize: fontSizeDynamic(16) },
                                }, sx: { mt: '16px' }, id: 'currency', disabled: true, "data-testid": 'currency' })] }))] })), _jsx(Typography, __assign({ className: 'employment', sx: { mt: '108px', mb: '1rem', fontSize: fontSizeDynamic(16) } }, { children: "Contract Remuneration" })), _jsxs(Grid, __assign({ container: true, spacing: 6 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Min Rate" })), _jsx(TPTextInput, { value: formData.minRate, InputProps: {
                                    readOnly: !(formData.employmentType.contract ||
                                        formData.employmentType.temporary),
                                }, variant: 'standard', onClick: handleClick('minRate'), onChange: handleInputChange('minRate'), placeholder: 'Enter Value', inputProps: {
                                    className: 'minRate',
                                    'data-testid': 'min-rate',
                                    sx: { fontSize: fontSizeDynamic(16) },
                                }, sx: { mt: '16px' }, id: 'minRate' }), errors.minRate && (_jsx(Typography, __assign({ color: 'error', className: 'error_message', id: 'minRate-error', "data-testid": 'minRate-error', fontSize: fontSizeDynamic(16) }, { children: "Please fill out the field ".concat(errors.minRate) })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Max Rate" })), _jsx(TPTextInput, { value: formData.maxRate, variant: 'standard', InputProps: {
                                    readOnly: !(formData.employmentType.contract ||
                                        formData.employmentType.temporary),
                                }, onClick: handleClick('maxRate'), onChange: handleInputChange('maxRate'), placeholder: 'Enter Value', inputProps: {
                                    className: 'maxRate',
                                    sx: { fontSize: fontSizeDynamic(16) },
                                }, sx: { mt: '16px' }, id: 'maxRate', "data-testid": 'maxRate' }), errors.maxRate && (_jsx(Typography, __assign({ color: 'error', className: 'error_message', id: 'maxRate-error', "data-testid": 'maxRate-error', fontSize: fontSizeDynamic(16) }, { children: "Please fill out the field ".concat(errors.maxRate) })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Rate Period (Hourly)" })), _jsx("span", __assign({ className: 'preference-info', title: 'Rate Period is set to hourly by default' }, { children: _jsx(TPSvgIcon, { width: 18, height: 18, resourceName: 'info-icon' }) }))] })), _jsx(TPTextInput, { value: formData.ratePeriod, variant: 'standard', InputProps: {
                                    readOnly: true,
                                }, sx: { mt: '10px' }, disabled: true, placeholder: 'Hourly', inputProps: {
                                    className: 'ratePeriod',
                                    sx: { fontSize: fontSizeDynamic(16) },
                                }, className: 'ratePeriod', id: 'ratePeriod', "data-testid": 'ratePeriod' })] }))] })), errors.contractError && (_jsx(Grid, __assign({ item: true, xs: 12, sx: { pt: '1rem ' } }, { children: _jsx(Typography, __assign({ color: 'error', id: 'temporary-error', "data-testid": 'temporary-error', className: 'error_message', fontSize: fontSizeDynamic(16) }, { children: errors.contractError })) }))), errors.rateValidation && (_jsx(Grid, __assign({ item: true, xs: 12, sx: { pt: '1rem' } }, { children: _jsx(Typography, __assign({ color: 'error', id: 'rate-error', "data-testid": 'rate-error', className: 'error_message' }, { children: "Min Rate should be less than Max Rate" })) }))), _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ className: 'employment', sx: { mt: '48px', mb: '1rem', fontSize: fontSizeDynamic(16) } }, { children: "Permanent Employment Remuneration" })), _jsxs(Grid, __assign({ container: true, spacing: 6 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Min Salary" })), _jsx(TPTextInput, { value: formData.minSalary, variant: 'standard', InputProps: {
                                            readOnly: !formData.employmentType.permanent,
                                        }, onClick: handleClick('minSalary'), onChange: handleInputChange('minSalary'), placeholder: 'Enter Value', inputProps: {
                                            className: 'minSalary',
                                            'data-testid': 'min-salary',
                                            sx: { fontSize: fontSizeDynamic(16) },
                                        }, sx: { mt: '16px' }, id: 'minSalary' }), errors.minSalary && (_jsx(Typography, __assign({ color: 'error', className: 'error_message', id: 'minSalary-error', "data-testid": 'minSalary-error' }, { children: "Please fill out the field ".concat(errors.minSalary) })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Max Salary" })), _jsx(TPTextInput, { value: formData.maxSalary, variant: 'standard', InputProps: {
                                            readOnly: !formData.employmentType.permanent,
                                        }, onClick: handleClick('maxSalary'), onChange: handleInputChange('maxSalary'), placeholder: 'Enter Value', inputProps: {
                                            className: 'maxSalary',
                                            sx: { fontSize: fontSizeDynamic(16) },
                                        }, sx: { mt: '16px' }, id: 'maxSalary', "data-testid": 'maxSalary' }), errors.maxSalary && (_jsx(Typography, __assign({ color: 'error', className: 'error_message', id: 'maxSalary-error', "data-testid": 'maxSalary-error' }, { children: "Please fill out the field ".concat(errors.maxSalary) })))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, lg: 4 }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(FormLabel, __assign({ className: 'formLabel', sx: { fontSize: fontSizeDynamic(16) } }, { children: "Salary Period (Annual)" })), _jsx("span", __assign({ className: 'preference-info', title: 'Salary Period is set to Annual by default' }, { children: _jsx(TPSvgIcon, { width: 18, height: 18, resourceName: 'info-icon' }) }))] })), _jsx(TPTextInput, { value: formData.salaryPeriod, variant: 'standard', InputProps: {
                                            readOnly: true,
                                        }, sx: { mt: '10px' }, disabled: true, placeholder: 'Annual', inputProps: {
                                            className: 'salaryPeriod',
                                            sx: { fontSize: fontSizeDynamic(16) },
                                        }, id: 'salaryPeriod', "data-testid": 'salaryPeriod' })] }))] })), errors.permanentError && (_jsx(Grid, __assign({ item: true, xs: 12, sx: { pt: '1rem' } }, { children: _jsx(Typography, __assign({ color: 'error', id: 'permanent-error', "data-testid": 'permanent-error', className: 'error_message' }, { children: errors.permanentError })) }))), errors.salaryValidation && (_jsx(Grid, __assign({ item: true, xs: 12, sx: { pt: '1rem' } }, { children: _jsx(Typography, __assign({ color: 'error', id: 'salary-validation-error', "data-testid": 'salary-validation-error', className: 'error_message' }, { children: "Min Salary should be less than Max Salary" })) })))] }), _jsx(Grid, __assign({ container: true, justifyContent: 'end', sx: { mt: '48px' } }, { children: _jsxs(Grid, __assign({ item: true, display: 'flex', direction: 'row', gap: 1 }, { children: [_jsx(TPButton, { onClick: handleDiscardChanges, children: 'Discard Changes', color: 'secondary', sx: { fontSize: fontSizeDynamic(14) }, id: 'discard-changes', "data-testid": 'discard-changes', disabled: btnDisabled }), _jsx(TPButton, { onClick: function () { return handleSavePreferences('save'); }, children: 'Save Preference', color: 'primary', id: 'save-preference', "data-testid": 'save-preference', disabled: btnDisabled, sx: { fontSize: fontSizeDynamic(14) } })] })) })), isLoading && _jsx(TPProgressModal, { isLoading: isLoading }), isSelectContact && (_jsx(TPCustomModal, { id: 'contact-preference-modal', "data-testid": 'contact-preference-modal', open: isSelectContact, PaperProps: {
                    sx: {
                        minWidth: { xs: '374px', sm: '41.125rem' },
                    },
                }, slotProps: {
                    backdrop: { style: { backdropFilter: 'none !important' } },
                }, DialogActionsProps: {
                    sx: { padding: '0px' },
                }, children: _jsx(ContactPreference, { onClick: setIsSelectContact, successMessage: setCPSuccess, errorMessage: setCPError }) })), navigationGuardModalPopup && (_jsx(NavigationGuard, { open: navigationGuardModalPopup, cancelButton: true, cancel: function () {
                    return dispatch(setNavigationGuardModalPopup({
                        navigationGuardModalPopup: false,
                    }));
                }, saveButton: true, saveAndProceed: function () { return handleSavePreferences('guard'); }, saveAndProceedDisable: btnDisabled, errorsText: errors, errorMessage: errorMessage }))] })));
};
export default Preference;
