var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import { Link } from 'react-router-dom';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { LearningBannerStyle } from './InfoCenter.styled';
import { useAppSelector } from '../../store/hook';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { useEffect, useState } from 'react';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Learning = function () {
    var _a = useState(true), renderComponent = _a[0], setRenderComponent = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    useEffect(function () {
        setRenderComponent(entitlementsData.entitlementDto.candidateInfoCenterJson.learning);
    }, [entitlementsData]);
    if (!renderComponent)
        return _jsx(NoPageFound, {});
    return (_jsx(LearningBannerStyle, { children: _jsxs(Box, __assign({ className: 'banner img-1', id: 'learning-banner-1' }, { children: [_jsx(Typography, __assign({ variant: 'h2', id: 'learning-poin', fontSize: fontSizeDynamic(32) }, { children: "Tandym's Learning Resource Center" })), _jsx(Typography, __assign({ variant: 'h3', id: 'learning-sub-title', fontSize: fontSizeDynamic(18) }, { children: "\u201CEmpowering Growth, One Skill at a Time\u201D" })), _jsx(Typography, __assign({ variant: 'h6', id: 'learning-banner-info', fontSize: fontSizeDynamic(18) }, { children: "The Learning Resource Center is designed to foster Professional Growth, Skill Development, and Knowledge Sharing." })), _jsx(Box, __assign({ className: 'action-btns' }, { children: _jsx(Link, __assign({ to: '/learning' }, { children: _jsxs(TPButton, __assign({ color: 'primary', sx: { fontSize: fontSizeDynamic(14) } }, { children: ["Start Learning", _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) })) }))] })) }));
};
export default Learning;
